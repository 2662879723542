import React, { Fragment, useEffect, useState } from "react";
import Banner from "../../Components/Common/Banner";
import { useDispatch, useSelector } from "react-redux";
import { fetchComboList } from "../../Components/Reducers/comboListSlice";
import CombopackCard from "./CombopackCard";
import { TextInput } from "../../Components/FormComp";
const CombopackList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchComboList({
        attractionId: 1,
        currencyCode: "AED",
        platformId: 1,
      })
    );
  }, [dispatch]);
  const comboList = useSelector((state) => state.comboList.data);
  const comboIsLoading = useSelector((state) => state.comboList.isLoading);
  const [filterComboList, setfilterComboList] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  useEffect(() => {
    applyFilter();
  }, [searchKey, comboList]);

  const applyFilter = () => {
    let updatedList = comboList;

    if (searchKey !== "") {
      updatedList = updatedList.filter((item) =>
        item.comboName.toLowerCase().includes(searchKey.toLowerCase())
      );
    }

    setfilterComboList(updatedList);
  };
  return (
    <Fragment>
      <Banner title="Combo List" />

      <div className="container-fluid team py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto pb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 800 }}
          >
            <h1 className="display-5 text-capitalize mb-3">
              Combo<span className="text-primary">pack</span> Offers
            </h1>
            <p className="mb-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut amet
              nemo expedita asperiores commodi accusantium at cum harum,
              excepturi, quia tempora cupiditate! Adipisci facilis modi quisquam
              quia distinctio,
            </p>
          </div>

          <div class="container-fluid categories pb-5">
            <div class="container pb-5">
              <div>
                <TextInput
                  placeholder="Search combo pack here"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </div>
              <div className="md:flex flex-wrap justify-between">
                {filterComboList &&
                  filterComboList.map((item, index) => (
                    <div className="md:w-[24%] mb-20" key={index}>
                      <CombopackCard item={item} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CombopackList;
