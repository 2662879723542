import React, { Fragment, useEffect, useState } from "react";
import Banner from "../../Components/Common/Banner";
import { useParams } from "react-router-dom";
import { instance } from "../../Components/Others/AxiosInstance";
import ComboDescription from "./ComboDescription";
import ComboBookingForm from "./ComboBookingForm";
import { useSelector } from "react-redux";
const ComboDetails = () => {
  const { id } = useParams();
  const [comboDetails, setcomboDetails] = useState(null);
  const currentCurrency = useSelector((state) => state.authData);
  useEffect(() => {
    getComboDetails();
  }, []);

  const getComboDetails = async () => {
    try {
      const response = await instance.post("getTravelPackComboOffer", {
        platformId: 2,
        currencyCode: "AED",
        attUniqueId: id,
      });
      setcomboDetails(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  //getTravelPackComboOffer
  return (
    <Fragment>
      <Banner title={comboDetails?.comboName} />
      <div className="container-fluid team py-5">
        <div className="container py-5">
          <div className="md:flex justify-between gap-5">
            <div className="md:w-4/12 w-full  md:order-last">
              {/* offer price and reguler price show here */}
              {/* <div className=" bg-white p-4 shadow-lg rounded">
               
                {comboDetails !== null && (
                  <div>
                    <div>{comboDetails?.offerB2cAdultPrice}</div>
                    <div>{comboDetails?.offerB2cChildPrice}</div>
                  </div>
                )}
              </div> */}
              <div className=" bg-white p-4 shadow-lg rounded mb-3">
                <div className="border-b-2 border-gray-200">
                  <h3 className="text-xl text-capitalize ">Price</h3>
                </div>
                <div className="font-bold  mb-3 md:flex md:justify-between">
                  <div className="text-red-500 line-through">
                    {comboDetails?.actualTvlPackB2cAdultPrice}{" "}
                    {currentCurrency.currency}
                  </div>
                  <div>
                    <span className="text-primary">
                      {" "}
                      {comboDetails?.offerB2cAdultPrice}{" "}
                      {currentCurrency.currency}
                    </span>
                  </div>
                </div>
              </div>
              <div className=" bg-white p-4 shadow-lg rounded">
                {comboDetails !== null && (
                  <ComboBookingForm
                    comboOfferId={comboDetails?.comboOfferId}
                    attName={comboDetails?.comboName}
                    adultPrice={comboDetails?.offerB2cAdultPrice}
                    childPrice={comboDetails?.offerB2cChildPrice}
                  />
                )}
              </div>
            </div>
            <div className="md:w-8/12 w-full">
              <div className="bg-white p-4 shadow-lg rounded">
                <ComboDescription
                  description={comboDetails?.comboDescription}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ComboDetails;
