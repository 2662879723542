import React, { Fragment, useState } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
const BookingTable = () => {
  const [rowData, setRowData] = useState([]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([]);
  return (
    <Fragment>
      <div className="flex"></div>
      <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{ height: 500 }} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact rowData={rowData} columnDefs={colDefs} />
      </div>
    </Fragment>
  );
};

export default BookingTable;
