import React, { Fragment } from "react";

const LocationMap = () => {
  return (
    <Fragment>
      <div class="col-12">
        <div class="rounded">
          <iframe
            class="rounded w-100"
            style={{ height: "400px" }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d902.070362243873!2d55.29452222938783!3d25.26111597409959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f433fc9409919%3A0xf78752b41229b055!2sParmar%20Tourism%20LLC!5e0!3m2!1sen!2sin!4v1631808171513!5m2!1sen!2sin"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </Fragment>
  );
};

export default LocationMap;
