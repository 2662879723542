import React, { Fragment } from "react";

const Banner = (props) => {
  const { title } = props;
  return (
    <Fragment>
      <div className="container-fluid bg-breadcrumb">
        <div className="container text-center py-5" style={{ maxWidth: 900 }}>
          <h4
            className="text-white display-4 mb-4 wow fadeInDown"
            data-wow-delay="0.1s"
          >
            {title}
          </h4>
        </div>
      </div>
    </Fragment>
  );
};

export default Banner;
