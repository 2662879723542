import React, { Fragment, useEffect, useState } from "react";
import Banner from "../../Components/Common/Banner";
import { instance } from "../../Components/Others/AxiosInstance";
import { useParams } from "react-router-dom";
import TourpackDescription from "./TourpackDescription";
import TourBookingForm from "./TourBookingForm";

const TourDetails = () => {
  const { id } = useParams();

  const [tourpackDetails, settourpackDetails] = useState(null);
  useEffect(() => {
    getTourpackDetails();
  }, []);

  const getTourpackDetails = async () => {
    try {
      const response = await instance.post("gettourpackageDetails", {
        tourPackageId: id,
      });

      console.log(response.data);
      settourpackDetails(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <Banner title={tourpackDetails?.tourName} />
      <div className="container-fluid team py-5">
        <div className="container py-5">
          <div className="md:flex justify-between gap-5">
            <div className="md:w-4/12 w-full  md:order-last">
              <div className=" bg-white p-4 shadow-lg rounded">
                <TourBookingForm id={id} tourName={tourpackDetails?.tourName} />
              </div>
            </div>
            <div className="md:w-8/12 w-full">
              <div className="bg-white p-4 shadow-lg rounded">
                <TourpackDescription
                  description={tourpackDetails?.tourDescription}
                  packageDetail={tourpackDetails?.packageDetail}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TourDetails;
