import React, { Fragment, useState } from "react";
import { DateInput, TextInput, TextArea } from "../../Components/FormComp";
import { instance } from "../../Components/Others/AxiosInstance";
import Swal from "sweetalert2";
const TourBookingForm = ({ id, tourName }) => {
  const [formData, setFormData] = useState({
    enqCusName: "",
    enqMobileNumber: "",
    enqEmail: "",
    enqMessage: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const makeEnquiry = async () => {
    const submitData = {
      enquiryId: "",
      tourPackageId: id,
      tourPackageName: tourName,
      enqCusName: formData.enqCusName,
      enqEmail: formData.enqEmail,
      enqMobileNumber: formData.enqMobileNumber,
      enqMessage: formData.enqMessage,
      enquiryDate: new Date(),
      enqFollowUpStatus: "",
      platformId: 2,
    };
    console.log("form data", formData);
    // try {
    //   const response = await instance.post("setenquiry", submitData);
    //   console.log(response.data);
    //   Swal.fire({
    //     title: "Success",
    //     text: "Thank you for your interest, Our executive will contact soon",
    //     icon: "success",
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
  };
  return (
    <Fragment>
      <div>
        <div className="">
          <div className="font-bold border-b-2 border-gray-200 mb-3">
            <h3 className="text-3xl text-capitalize ">
              Enquiry<span className="text-primary"> Here</span>
            </h3>
          </div>

          <div>
            <TextInput
              placeholder="Name *"
              name="enqCusName"
              value={formData.enqCusName}
              onChange={handleChange}
            />
          </div>
          <div>
            <TextInput
              placeholder="Contact *"
              name="enqMobileNumber"
              value={formData.enqMobileNumber}
              onChange={handleChange}
            />
          </div>
          <div>
            <TextInput
              placeholder="Email *"
              name="enqEmail"
              value={formData.enqEmail}
              onChange={handleChange}
            />
          </div>
          <div>
            <TextArea
              placeholder="Details"
              name="enqMessage"
              value={formData.enqMessage}
              onChange={handleChange}
            />
          </div>
          <div>
            <div className="flex justify-end mt-3">
              <button
                className="btn btn-primary rounded "
                onClick={() => makeEnquiry()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TourBookingForm;
