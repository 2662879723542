import React, { Fragment, useEffect } from "react";
import Banner from "../../Components/Common/Banner";
import CartForm from "./CartForm";
import CartDataList from "./CartDataList";
import { useSelector } from "react-redux";

const CartDetails = () => {
  const cartlist = useSelector((state) => state.cartData.data);
  const finalAmount = cartlist.reduce((sum, item) => sum + item.bookTotal, 0);
  // window.dataLayer = window.dataLayer || [];
  useEffect(() => {
    // coretag3manager();
    if (cartlist.length > 0) {
      console.log("ksan cxsc", cartlist.length, cartlist);

      const tempga4data = cartlist.map((item) => ({
        att_id: item.attractionId,
        att_name: item.attName,
        ticket_type: item.tktName,
        adult_count: Number(item.nofAdult),
        child_count: Number(item.nofChild),
        ticket_id: item.ticketTypeId,
        total_price: item.bookTotal,
        total_quantity: Number(item.nofAdult) + Number(item.nofChild),
      }));

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          currency: "AED",
          grand_total: finalAmount,
          items: tempga4data,
        },
      });

      console.log("tempga4datatempga4datatempga4data", tempga4data);
    }

    // ga4purchase();
  }, []);

  console.log("cartlistcartlistcartlistcartlist", cartlist);
  return (
    <Fragment>
      <Banner title="Pasenger Details" />
      <div className="container-fluid contact py-5">
        <div className="container py-5">
          <div className="md:flex justify-between">
            <div className="md:w-8/12 w-full md:order-first ">
              <div className="md:px-5">
                <CartForm cartlist={cartlist} />
              </div>
            </div>
            <div className="md:w-4/12 w-full  ">
              <CartDataList cartlist={cartlist} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CartDetails;
