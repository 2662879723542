import React, { Fragment, useEffect, useState } from "react";
import {
  TextInput,
  DateInput,
  TimeSlotButton,
  SelectInput,
  RadioInput,
} from "../../Components/FormComp";
import { instance } from "../../Components/Others/AxiosInstance";
import axios from "axios";
import { mofUrl, secretKey } from "../../Components/Others/ApiUrls";
import { keydata } from "../../Components/Reducers/authSlice";
import { useSelector } from "react-redux";
import FileSaver from "file-saver";
import Swal from "sweetalert2";

const AttractionBookingForm = (props) => {
  const { attractionTickets, attId } = props;
  const loginData = useSelector((state) => state.authData);
  const xmlRes = keydata();
  const hConfig = {
    headers: {
      "access-Key": xmlRes,
    },
  };
  const [burjTicketTimeSlot, setburjTicketTimeSlot] = useState(null);
  const [slotIsLoading, setslotIsLoading] = useState(false);
  const [ticketPrice, setticketPrice] = useState(null);
  const [mofError, setmofError] = useState(null);
  const [featureofmeasuemtimeslot, setfeatureofmeasuemtimeslot] = useState();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    tktId: "",
    bookCustomerEmail: "",
    bookMobileNumber: "",
    eventtypeId: null,
    resourceID: null,
    noOfAdult: 0,
    noOfChild: 0,
    travelDate: "",
    total: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = async (date) => {
    let formetDate = `${date.getFullYear()}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;

    setFormData((prevData) => ({
      ...prevData,
      travelDate: formetDate,
    }));
    console.log(attId, "feature of measuem");
    if (attId == 147) {
      console.log("feature of measuem");
      //getAvailability
      if (
        formData.fName == "" &&
        formData.lName == "" &&
        (formData.noOfAdult == 0 || formData.noOfChild == 0)
      ) {
        setmofError(
          "Please Ensure First Name , Last Name , Adult Or Child not null"
        );
      } else {
        await axios
          .post(mofUrl + "getAvailability", {})
          .then((res) => {
            console.log(res.data);
            setfeatureofmeasuemtimeslot();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      if (formData.resourceID !== null && formData.eventtypeId !== null) {
        // here get timeslot   getAvailability
        setslotIsLoading(true);
        console.log("formetDateformetDate", formetDate, {
          bookingDate: formetDate,
          eventTypeId: formData.eventtypeId,
          resourceId: formData.resourceID,
          secretKey: secretKey,
        });

        await instance
          .post("getBurjTimeSlotWithRates", {
            bookingDate: formetDate,
            eventTypeId: formData.eventtypeId,
            resourceId: formData.resourceID,
            secretKey: secretKey,
          })
          .then((res) => {
            console.log(res.data);
            setburjTicketTimeSlot(res.data.agentServiceEventsPrice);
            setslotIsLoading(false);
          })
          .catch((error) => {
            setslotIsLoading(true);
            console.log(error);
          });
      }
    }
  };

  const [selectedOption, setSelectedOption] = useState("2");

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const selectedBurjTimeSlot = (timeslot) => {
    console.log(timeslot);
  };

  const getTicketPrice = async (event) => {
    const { value, eventtypeId, resourceID } = event;

    setticketPrice(null);
    setFormData((prevData) => ({
      ...prevData,
      tktId: value,
      noOfAdult: 0,
      noOfChild: 0,
      eventtypeId: eventtypeId,
      resourceID: resourceID,
      travelDate: "",
    }));
    console.log("value", value, "eventtypeId", eventtypeId);
    if (attId != 147) {
      console.log("eventtypeId", eventtypeId);
      console.log("resourceID", resourceID);
      if (eventtypeId === null || eventtypeId == "0") {
        await instance
          .post("getAttractionTicketTypePrice", {
            ticketTypeId: value,
            agencyId:
              loginData?.data?.userType === "b2b" ? loginData?.data?.userId : 0,
            agencyUserId:
              loginData?.data?.userType === "b2bagent"
                ? loginData?.data?.userId
                : 0,
            attractionsId: attId,
          })
          .then((res) => {
            //b2cAdultPrice
            console.log("ishvusvcusuvcbs", res.data);
            setticketPrice({
              adultPrice:
                res.data.errorCode === 505
                  ? 0
                  : loginData?.data?.userType === "b2b" ||
                    loginData?.data?.userType === "b2bagent"
                  ? res.data.b2bAdultPrice
                  : res.data.b2cAdultPrice,
              childPrice:
                res.data.errorCode === 504
                  ? 0
                  : loginData?.data?.userType === "b2b" ||
                    loginData?.data?.userType === "b2bagent"
                  ? res.data.b2bChildPrice
                  : res.data.b2cChildPrice,
            });
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    const calculateTotal = () => {
      if (ticketPrice != null) {
        const total =
          ticketPrice.adultPrice * formData.noOfAdult +
          ticketPrice.childPrice * formData.noOfChild;

        setFormData((prevData) => ({
          ...prevData,
          total: total,
        }));
      }
    };
    calculateTotal();
  }, [ticketPrice, formData.noOfAdult, formData.noOfChild]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.tktId) newErrors.tktId = "Please select a ticket type.";
    if (formData.noOfAdult <= 0 && formData.noOfChild <= 0)
      newErrors.noOfTickets =
        "Please select at least one adult or child ticket.";
    if (!formData.travelDate)
      newErrors.travelDate = "Please select a travel date.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const addToCart = async () => {
    if (validateForm()) {
      console.log(formData);
      const submitdata = {
        bookedByBackOffice: false,
        bookedCreditLimit: 0,
        superAdminId: null,
        bookB2bId: loginData?.data?.userId,
        bookB2bUserId: 0,
        bookB2cId: 0,
        attractionsId: attId,
        bookCustomerName: formData.fName + formData.lName,
        bookCustomerEmail: formData.bookCustomerEmail,
        bookMobileNumber: formData.bookMobileNumber,
        ticketTypeId: formData.tktId,
        bookNofAdult: formData.noOfAdult,
        bookNofChild: formData.noOfChild,
        bookAdultPrice: ticketPrice?.adultPrice,
        bookChildPrice: ticketPrice?.childPrice,
        bookTravellDate: formData.travelDate, // travel date
        bookPaymentMode: 2,
        bookTotal: formData.total,
        bookingAddon: [],
        secretKey: secretKey,
        errorMessage: loginData?.data?.errorMessage,
        agentRefNumber: "",
      };

      console.log("formData", submitdata, xmlRes);

      Swal.fire({
        title: "Processing your request...",
        html: "Please wait while we process your booking.",
        allowOutsideClick: false, // Prevents closing by clicking outside
        didOpen: () => {
          Swal.showLoading(); // Shows loading indicator
        },
      });

      try {
        if (selectedOption === "1") {
          const paymentData = {
            paymentAgencyId: loginData?.data?.userId,
            paymentAgencyUserId: loginData?.data?.userId,
            paymentB2cId: 0,
            paymentCustomerName: formData.fName + formData.lName,
            paymentRemarks: formData.bookCustomerEmail,
            paymentAmount: formData.total,
            secretKey: secretKey,
            successUrl: `http://66.29.149.191:5030/ticket-booking-status/`,
            failureUrl: `http://66.29.149.191:5030/ticket-booking-status/`,
            temporaryBookingDto: submitdata,
          };

          const res = await instance.post(
            "setNetworkPaymentsDetail",
            paymentData
          );
          console.log(res.data);

          window.location.replace(res.data.paymentUrl);
        } else {
          const res = await instance.post("setbooking", submitdata, hConfig);
          console.log(res.data);

          const urlpath = `https://b2b.parmartours.com/filestorage/${res.data.bookingTickPdfPath}`;
          downloadFile(urlpath);
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Booking Failed",
          text: "There was an error processing your booking. Please try again.",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const downloadFile = async (url) => {
    // const url =
    //   "https://b2b.parmartours.com/filestorage/PT20240812923373%20-%20INVPT266743.pdf";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setFormData({
        fName: "",
        lName: "",
        tktId: "",
        bookCustomerEmail: "",
        bookMobileNumber: "",
        eventtypeId: null,
        resourceID: null,
        noOfAdult: 0,
        noOfChild: 0,
        travelDate: "",
        total: 0,
      });

      Swal.fire({
        title: "Success",
        text: "Your Ticket Has Been Downloaded Automatically",
        icon: "success",
      });

      const blob = await response.blob();
      FileSaver.saveAs(blob, "downloaded-file.pdf");
    } catch (error) {
      console.error("There was an error downloading the file:", error);
    }
  };

  return (
    <Fragment>
      <div className="">
        <div className="font-bold border-b-2 border-gray-200 mb-3">
          <h3 className="text-3xl text-capitalize ">
            Book<span className="text-primary"> Here</span>
          </h3>
        </div>

        <div className="flex justify-between gap-5 ">
          <div>
            <TextInput
              label="First Name"
              name="fName"
              value={formData.fName}
              onChange={handleChange}
            />
          </div>
          <div>
            <TextInput
              label="Last Name"
              name="lName"
              value={formData.lName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          <TextInput
            label="E-Mail"
            name="bookCustomerEmail"
            value={formData.bookCustomerEmail}
            onChange={handleChange}
          />
        </div>
        <div>
          <TextInput
            label="Contact Number"
            name="bookMobileNumber"
            value={formData.bookMobileNumber}
            onChange={handleChange}
          />
        </div>
        <SelectInput
          label="Select Ticket"
          options={attractionTickets}
          onChange={(e) => getTicketPrice(e)}
        />

        {errors.tktId && <span className="text-red-500">{errors.tktId}</span>}

        <div className="flex justify-between gap-5">
          <div>
            <TextInput
              label="Adult"
              type="number"
              disabled={ticketPrice?.adultPrice === 0 && true}
              name="noOfAdult"
              value={formData.noOfAdult}
              onChange={handleChange}
            />
            {errors.noOfTickets && (
              <span className="text-red-500">{errors.noOfTickets}</span>
            )}

            {ticketPrice &&
              (ticketPrice?.adultPrice === 0 ? (
                <span className="text-[10px] text-red-500">
                  Ticket Not Avaiable
                </span>
              ) : (
                <div className="text-[#1f2e4e]">
                  {ticketPrice.adultPrice + " aed"}
                </div>
              ))}
          </div>
          <div>
            <TextInput
              label="Child"
              type="number"
              disabled={ticketPrice?.childPrice === 0 && true}
              name="noOfChild"
              value={formData.noOfChild}
              onChange={handleChange}
            />
            {ticketPrice &&
              (ticketPrice.childPrice === 0 ? (
                <span className="text-[10px] text-red-500">
                  Ticket Not Available
                </span>
              ) : (
                <div className="text-[#1f2e4e]">
                  {ticketPrice.childPrice + " aed"}
                </div>
              ))}
          </div>
        </div>
        <div className="w-full">
          <DateInput
            label="Select Date"
            minDate={new Date()}
            selectedDate={formData.travelDate}
            handleDateChange={handleDateChange}
          />
        </div>
        {errors.travelDate && (
          <span className="text-red-500">{errors.travelDate}</span>
        )}
        {slotIsLoading && <div>Loading Please Wait</div>}
        {mofError !== null && mofError}

        {burjTicketTimeSlot !== null && (
          <div className="flex flex-wrap justify-between">
            {burjTicketTimeSlot.map((item, index) => (
              <div key={index}>
                <TimeSlotButton
                  name={
                    item.startDateTime.slice(11, -3) +
                    "-" +
                    item.endDateTime.slice(11, -3) +
                    "(" +
                    item.available +
                    ")"
                  }
                  onClick={() => selectedBurjTimeSlot(item)} // Wrap in an arrow function
                />
              </div>
            ))}
          </div>
        )}

        <div className="flex justify-between py-3">
          <div>
            <RadioInput
              label="Credit/Debit Card (Online Payment)"
              value="1"
              name="paymentType"
              onChange={handleRadioChange}
              checked={selectedOption === "1"}
            />
          </div>
          <div>
            <RadioInput
              label="Agents Credit"
              value="2"
              name="paymentType"
              onChange={handleRadioChange}
              checked={selectedOption === "2"}
            />
          </div>
        </div>

        <h3 className="text-3xl text-capitalize font-bold ">
          Total :<span className="text-primary"> {formData.total} AED</span>
        </h3>

        <div className="flex justify-between mt-5">
          {/* <div>
            <button
              className="btn btn-primary rounded "
              onClick={() => addToCart("cart")}
            >
              Add To Cart
            </button>
          </div> */}
          <div>
            <button
              className="btn btn-primary rounded "
              onClick={() => addToCart("cart")}
            >
              Confirm Book
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AttractionBookingForm;
