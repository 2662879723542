import React, { Fragment, useState, useEffect } from "react";
import { DateInput, TextInput, CountButton } from "../../Components/FormComp";
import { useSelector } from "react-redux";
import { secretKey } from "../../Components/Others/ApiUrls";
import { instance } from "../../Components/Others/AxiosInstance";
import { keydata } from "../../Components/Reducers/authSlice";
const ComboBookingForm = ({
  attName,
  comboOfferId,
  adultPrice,
  childPrice,
}) => {
  const loginData = useSelector((state) => state.authData);
  const xmlRes = keydata();
  const hConfig = {
    headers: {
      "access-Key": xmlRes,
    },
  };

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    userType: 1,
    platformId: 2,
    attName: attName,
    tktName: attName,
    bookingType: 2,
    comboOfferId: comboOfferId,
    superAdminId: 0,
    bookCustomerName: "",
    bookMobileNumber: "",
    bookCustomerEmail: "",
    bookPaymentMode: 1,
    bookTravellDate: "",
    bookTotal: "",
    bookAdultPrice: adultPrice,
    bookChildprice: childPrice,
    bookNofAdult: 0,
    bookNofChild: 0,
    b2cId: loginData?.data?.userId,
    apiTicket: "false",
    bookedByBackOffice: "false",
    secretKey: secretKey,
    errorMessage: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDateChange = (date) => {
    console.log("ivuyvuyvuvuv", date);
    let formetDate = `${date.getFullYear()}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;

    console.log("kbibjbjb", formetDate);

    setFormData((prevData) => ({
      ...prevData,
      bookTravellDate: formetDate,
    }));
  };
  useEffect(() => {
    const calculateTotal = () => {
      const total =
        formData.bookAdultPrice * formData.bookNofAdult +
        formData.bookChildprice * formData.bookNofChild;

      setFormData((prevData) => ({
        ...prevData,
        bookTotal: total,
      }));
    };
    calculateTotal();
  }, [adultPrice, childPrice, formData.bookNofAdult, formData.bookNofChild]);

  const validateForm = () => {
    const newErrors = {};

    if (formData.bookNofAdult <= 0 && formData.bookNofChild <= 0)
      newErrors.bookNofAdult =
        "Please select at least one adult or child ticket.";
    if (!formData.bookTravellDate)
      newErrors.bookTravellDate = "Please select a travel date.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const postToApi = async () => {
    console.log("formDataformData", formData);
    if (validateForm()) {
      try {
        const response = await instance.post(
          "setComboBooking",
          formData,
          hConfig
        );
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <Fragment>
      <div>
        <div className="">
          <div className="font-bold border-b-2 border-gray-200 mb-3">
            <h3 className="text-3xl text-capitalize ">
              Book<span className="text-primary"> Here</span>
            </h3>
          </div>
          <div>
            {/* <div>
              <TextInput
                label="Passenger Name"
                placeholder="Name *"
                name="bookCustomerName"
                value={formData.bookCustomerName}
                onChange={handleChange}
              />
            </div>
            <div>
              <TextInput
                label="Email"
                placeholder="Email *"
                name="bookCustomerEmail"
                value={formData.bookCustomerEmail}
                onChange={handleChange}
              />
            </div>
            <div>
              <TextInput
                label="Contact Number"
                placeholder="Contact *"
                name="bookMobileNumber"
                value={formData.bookMobileNumber}
                onChange={handleChange}
              />
            </div> */}

            <div className="mt-3 ">
              <CountButton
                label="Adult"
                name="bookNofAdult"
                value={formData.bookNofAdult}
                onChange={handleChange}
                // disabled={ticketPrice?.adultPrice === 0}
              />
              <div className="text-[#1f2e4e] font-extrabold">
                {adultPrice}{" "}
                <span className="text-gray-600 font-normal text-[13px]">
                  {" "}
                  AED
                </span>
              </div>
            </div>
            <hr className="solid"></hr>
            <div className="mt-3">
              <CountButton
                label="Child"
                name="bookNofChild"
                value={formData.bookNofChild}
                onChange={handleChange}
              />
              <div className="text-[#1f2e4e] font-extrabold">
                {childPrice}{" "}
                <span className="text-gray-600 font-normal text-[13px]">
                  {" "}
                  AED
                </span>
              </div>
            </div>

            {errors.bookNofAdult && (
              <span className="text-red-500">{errors.bookNofAdult}</span>
            )}
            <hr className="solid"></hr>

            <div>
              <DateInput
                label="Select Date"
                minDate={new Date()}
                selectedDate={formData.bookTravellDate}
                handleDateChange={handleDateChange}
              />
              {errors.bookTravellDate && (
                <span className="text-red-500">{errors.bookTravellDate}</span>
              )}
            </div>

            <h3 className="text-xl md:text-3xl text-capitalize font-bold ">
              Total :
              <span className="text-primary"> {formData.bookTotal} AED</span>
            </h3>
            <div>
              <div className="flex justify-end mt-3">
                <button
                  className="btn btn-primary rounded "
                  onClick={() => postToApi()}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ComboBookingForm;
