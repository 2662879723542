import React, { Fragment } from "react";
import { imageurl } from "../../Components/Others/ApiUrls";
const TourpackDescription = (props) => {
  const { description, packageDetail } = props;
  return (
    <Fragment>
      <div className="font-bold border-b-2 border-gray-200 mb-3">
        <h3 className="text-3xl text-capitalize ">
          About<span className="text-primary"> Package</span>
        </h3>
      </div>
      <div dangerouslySetInnerHTML={{ __html: description }} />

      <div className="font-bold border-b-2 border-gray-200 mb-3">
        <h3 className="text-3xl text-capitalize ">
          Tour<span className="text-primary"> Itinerary</span>
        </h3>
      </div>
      <div className="mt-10">
        {packageDetail?.map((item, index) => (
          <div key={index} className="py-6">
            <div className="bg-white rounded border-2 border-[#000C21]">
              <h5 className="text-xl text-capitalize font-bold p-3 ">
                Day<span className="text-primary"> {index + 1}</span>
              </h5>
              <div className="bg-[#f2f2f2] rounded-b-lg">
                <div className="p-3 md:flex justify-between gap-5">
                  <div className="w-4/12">
                    <img src={imageurl + item.packageSitePhoto} />
                  </div>
                  <div className="w-8/12">{item.packageDayActivity}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default TourpackDescription;
