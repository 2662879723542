import React, { Fragment, useEffect } from "react";
import Banner from "../../Components/Common/Banner";
import GenerateTickets from "./GenerateTickets";
import { useSelector } from "react-redux";
const BookingStatus = () => {
  const cartlist = useSelector((state) => state.cartData.data);
  const loginData = useSelector((state) => state.authData);
  useEffect(() => {}, [cartlist]);
  return (
    <Fragment>
      <Banner title="Booking Status" />
      <div className="container-fluid contact py-5">
        <div className="container py-5">
          <GenerateTickets cartlist={cartlist} loginData={loginData} />
        </div>
      </div>
    </Fragment>
  );
};

export default BookingStatus;
