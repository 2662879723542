import React, { Fragment } from "react";

const Herosection = () => {
  return (
    <Fragment>
      {/* Carousel Start */}
      <div className="header-carousel">
        <div
          id="carouselId"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="false"
        >
          <ol className="carousel-indicators">
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to={0}
              className="active"
              aria-current="true"
              aria-label="First slide"
            />
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to={1}
              aria-label="Second slide"
            />
          </ol>
          <div className="carousel-inner" role="listbox">
            <div className="carousel-item active">
              <img
                src="/img/home1.jpg"
                className="img-fluid w-100"
                alt="First slide"
              />
              <div className="carousel-caption">
                <div className="container py-4">
                  <div className="row g-5">
                    <div
                      className="col-lg-6 fadeInLeft animated"
                      data-animation="fadeInLeft"
                      data-delay="1s"
                      style={{ animationDelay: "1s" }}
                    ></div>
                    <div
                      className="col-lg-6 d-none d-lg-flex fadeInRight animated"
                      data-animation="fadeInRight"
                      data-delay="1s"
                      style={{ animationDelay: "1s" }}
                    >
                      <div className="text-start">
                        <h1 className="display-5 text-white">
                          Trending Attractions and Activities
                        </h1>
                        <p>Discover the latest trending activities in Dubai </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="/img/home2.jpg"
                className="img-fluid w-100"
                alt="First slide"
              />
              <div className="carousel-caption">
                <div className="container py-4">
                  <div className="row g-5">
                    <div
                      className="col-lg-6 fadeInLeft animated"
                      data-animation="fadeInLeft"
                      data-delay="1s"
                      style={{ animationDelay: "1s" }}
                    ></div>
                    <div
                      className="col-lg-6 d-none d-lg-flex fadeInRight animated"
                      data-animation="fadeInRight"
                      data-delay="1s"
                      style={{ animationDelay: "1s" }}
                    >
                      <div className="text-start">
                        <h1 className="display-5 text-white">
                          Most Popular Tour Packages
                        </h1>
                        <p>
                          Discover the best Dubai tour packages and explore the
                          most popular tours in the city.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Carousel End */}
    </Fragment>
  );
};

export default Herosection;
