import React, { Fragment } from "react";
import Banner from "../../Components/Common/Banner";
import BookingTable from "./BookingTable";

const BookingDetails = () => {
  return (
    <Fragment>
      <Banner title="Booking Details" />
      <div className="container-fluid team py-5">
        <div className="container py-5">
          <BookingTable />
        </div>
      </div>
    </Fragment>
  );
};

export default BookingDetails;
