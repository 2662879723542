import logo from "./logo.svg";
import "./App.css";
import { Fragment, useEffect } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import ContainerComponent from "./Components/Frames/ContainerComponent";
import { useSelector, useDispatch } from "react-redux";
import { fetchCartDetails } from "./Components/Reducers/cartDataSlice";
import { fetchContantLabels } from "./Components/Reducers/allConstantValues";
import { secretKey } from "./Components/Others/ApiUrls";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import whatesappimage from "./whatsapp.png";
import axios from "axios";
import {
  Attractions,
  Contact,
  Home,
  AttractionDetails,
  Aboutus,
  CombopackList,
  ToursList,
  TourDetails,
  Login,
  ComboDetails,
  BookingDetails,
  CartDetails,
  BookingStatus,
  Register,
  OtpVerification,
  ForgetPassword,
  ResetPassword,
} from "./Pages";

function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const loginData = useSelector((state) => state.authData);

  useEffect(() => {
    getTempId();
  }, []);
  let tempId = sessionStorage.getItem("tempIdtpb2bnew");

  useEffect(() => {
    dispatch(
      fetchCartDetails({
        platformId: 2,
        userType: 3,
        b2cId: loginData?.data?.userId ? loginData?.data?.userId : tempId,
        secretKey: secretKey,

        tempRef: tempId,
      })
    );
  }, [tempId]);

  useEffect(() => {
    //  langCode: selectedLang,

    dispatch(
      fetchContantLabels({
        langCode: loginData.language,
      })
    );
  }, [loginData.language]);

  const getTempId = async () => {
    try {
      const response = await axios.get("https://api.ipify.org/?format=json");

      console.log(response.data.ip);
      const parts = response.data.ip.split(".");
      const lastTwoPartsString = parts.slice(-3).join("");
      console.log(lastTwoPartsString);

      sessionStorage.setItem("tempIdtpb2bnew", lastTwoPartsString);
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error for handling at a higher level
    }
  };

  const PublicRoute = ({ component: Component, ...rest }) => {
    const authToken = loginData?.isLoggedIn;
    if (authToken) {
      return <Navigate to="/" />;
    }
    return <Component {...rest} />;
  };
  const CheckLogin = ({ component: Component, ...rest }) => {
    const authToken = loginData?.isLoggedIn;
    if (!authToken) {
      return <Navigate to="/" />;
    }
    return <Component {...rest} />;
  };
  return (
    <Fragment>
      <div>
        <a
          href="https://wa.me/+971529418587"
          style={{
            alignItems: "center",
            bottom: "20px",
            color: "rgb(255, 255, 255)",
            display: "flex",
            left: "20px",
            position: "fixed",
            borderRadius: "50px",
            padding: "10px 20px",
            textDecoration: "none",
            zIndex: "10",
            transition:
              "transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              height: "70px",
            }}
            src={whatesappimage}
            alt="WhatsApp icon"
            className="whatsapp-icon"
          />
        </a>
      </div>
      <Routes>
        <Route path="/" element={<ContainerComponent />}>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/attraction-list" element={<Attractions />} />
          <Route
            path="/attraction-details/:id"
            element={<AttractionDetails />}
          />
          <Route path="/combopack-list" element={<CombopackList />} />
          <Route path="/combopack-details/:id" element={<ComboDetails />} />
          <Route path="/tourpackages-list" element={<ToursList />} />
          <Route path="/tourpackages-details/:id" element={<TourDetails />} />
          <Route path="/cart-details" element={<CartDetails />} />
          <Route path="/ticket-booking-status" element={<BookingStatus />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/register-user" element={<Register />} />
          <Route path="/otpverification/:id" element={<OtpVerification />} />
          <Route path="/resetpassword/:id" element={<ResetPassword />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route
            path="/login-user"
            element={<PublicRoute component={Login} />}
          />
          <Route
            path="/user-booking-details"
            element={<CheckLogin component={BookingDetails} />}
          />
        </Route>
      </Routes>
    </Fragment>
  );
}

//203.161.38.98
export default App;
